<!-- @format -->
<script>
	import Case from 'case';
	import { currentData, commonParams } from '../../stores/stores';
	import AutosaveTextarea from '../../../components/ui/autosave_textarea.svelte';

	export let attribute;
	export let label;
	export let placeholder;
	export let validates;
	export let show;

	$: fieldName = {
		...$commonParams,
		attribute: Case.snake(attribute),
		label: label,
		value: $currentData && $currentData[attribute],
		placeholder: placeholder,
		validates: validates,
	};
</script>

{#if show}
	<AutosaveTextarea {...fieldName} />
{/if}
